<template>
  <div>
    <vs-row
      class="payments-page custom-mob-m md:justify-center lg:justify-between"
    >
      <vs-col
        vs-w="12"
        vs-lg="12"
        vs-sm="12"
        vs-xs="12"
        class="m-0 my-5"
      >
        <h1 class="textBalck">{{ legals.header }}</h1>
      </vs-col>
      <vs-col
        vs-w="12"
        vs-lg="12"
        vs-sm="12"
        vs-xs="12"
        class="m-0 my-5"
      >
        <p v-html="legals.content"></p>
      </vs-col>
    </vs-row>
    <div class="mobile-footer">
      <the-footer
        v-if="footerBind"
        :paymentRequestDetail="paymentRequestDetail"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TheFooter from "@/layouts/guest-components/TheFooter";

export default {
  components: {
    TheFooter,
  },
  data() {
    return {
      paymentRequestDetail: {},
      legals: {},
      footerBind: false,
    };
  },
  methods: {
    ...mapActions("payment", ["getPaymentDetails"]),
    fetchPayNowLegals() {
      this.$vs.loading();
      this.$store
        .dispatch("legals/fetchLegalsById", this.$route.params.legalid)
        .then((result) => {
          this.$vs.loading.close();
          this.legals = result.data.data;

        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
  },
  created() {
    let paymentRequestId = this.$route.params.id;
    this.$vs.loading();
    this.getPaymentDetails(paymentRequestId)
      .then((response) => {
        let responseData = response.data.data;
        this.paymentRequestDetail = responseData.paymentDetails;
        this.footerBind = true;
        this.$vs.loading.close();
      })
      .catch((err) => {

        this.$vs.loading.close();

        this.$router.push({ name: "payment-error" });
      });

    this.fetchPayNowLegals();
  },
};
</script>
